@import 'vars';

.wrapper {
	display: none !important;
}

#browser-warning {
	display: block;
	margin-top: 15%;
}
